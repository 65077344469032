/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    strong: "strong",
    code: "code",
    pre: "pre"
  }, _provideComponents(), props.components), {Admonition} = _components;
  if (!Admonition) _missingMdxReference("Admonition", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Send a POST request to the following endpoint:"), "\n", React.createElement(_components.p, null, "POST ", React.createElement(_components.a, {
    href: "https://api.uat.payroc.com/v1/payments"
  }, "https://api.uat.payroc.com/v1/payments")), "\n", React.createElement(Admonition, null, React.createElement(_components.p, null, React.createElement(_components.strong, null, "Note:"), " Set the value for the ", React.createElement(_components.strong, null, "autoCapture"), " parameter to ", React.createElement(_components.code, null, "false"), ".")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Example response")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-json"
  }, "{\n\t\"paymentId\": \"C7BHY7KWCW\",\n\t\"processingTerminalId\": \"3204001\",\n\t\"operator\": \"Davi-Crisostomo-CHP\",\n\t\"order\": {\n\t\t\"orderId\": \"1234567890Q1\",\n\t\t\"dateTime\": \"2023-06-20T21:03:30.925+01:00\",\n\t\t\"description\": \"PreAuth Card Transaction (WEB) - Sale - KEYED (plain_text) with CVV\",\n\t\t\"amount\": 12346,\n\t\t\"currency\": \"USD\"\n\t},\n\t\"card\": {\n\t\t\"type\": \"Visa Credit\",\n\t\t\"entryMethod\": \"keyed\",\n\t\t\"cardholderName\": \"Davi\",\n\t\t\"cardNumber\": \"444433******1111\",\n\t\t\"expiryDate\": \"1223\",\n\t\t\"securityChecks\": {\n\t\t\t\"cvvResult\": \"M\",\n\t\t\t\"avsResult\": \"Y\"\n\t\t}\n\t},\n\t\"transactionResult\": {\n\t\t\"type\": \"sale\",\n\t\t\"status\": \"pending\",\n\t\t\"approvalCode\": \"OK24233\",\n\t\t\"authorizedAmount\": 12346,\n\t\t\"currency\": \"USD\",\n\t\t\"responseCode\": \"A\",\n\t\t\"responseMessage\": \"OK24233\"\n\t}\n}\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
